import React from 'react'
import ServiceSidebarAbInitio from './ServiceSidebarAbInitio'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentAbInitio = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">AbInitio Services</span>
                            <h3>About</h3>
                            <p>Full fledge AbInitio modules stack support, from GDE for data processing, MHUB and Data Catalog for Governance and Lineage implementations, Express>IT and BRE for the various business rules and data quality until Continuous>Flows and Control>Center for orchestration and scheduling.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Support</h3>
                                        <ul>
                                            <li>Automation</li>
                                            <li>Data Generation</li>
                                            <li>Data Anonymization</li>
                                            <li>Performance</li>
                                            <li>Orchestration</li>
                                            <li>Life Cycle</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>A full Data Platform ensuring and supporting all possible business and technical requirements in any type of organization, AbInitio delivers performance and scalability for Digital and Self-Service Enablement, Automation, On Premises and Cloud Native and Future proofing & Modernization to ensure long-term investment protection.</p>
                            <p>All services and case studies delivered by us can be supported by AbInitio. Industry standards and innovative or experienced frameworks are easily to use and adapt for the tool-stack, the best money can buy.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Real Estate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarAbInitio />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentAbInitio